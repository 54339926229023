















































import {
  defineComponent, onMounted, useContext, useRouter,
} from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import type { NuxtError } from '@nuxt/types';
import { SfButton } from '@storefront-ui/vue';

export default defineComponent({
  components: {
    SfButton,
  },
  layout: 'basic',
  props: {
    error: {
      type: Object as PropType<NuxtError>,
      required: true,
    },
  },
  setup({ error }) {
    const router = useRouter();
    const { app } = useContext();

    onMounted(() => {
      // @ts-ignore
      if (error.statusCode && error.statusCode === 404) {
        app.$googleTagManager.push({
          event: 'page not found',
          ...(router.currentRoute?.fullPath && {
            requestPath: router.currentRoute?.fullPath,
          }),
        });
      }
    });

    return {};
  },
});
