






























































import { useRoute, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'TypLayout',
  components: {
    HeaderSteps: () => import(/* webpackPrefetch: true */ '~/modules/checkout/components/HeaderSteps.vue'),
  },
  setup() {
    const route = useRoute();

    return {
      route,
    };
  },
});
