export default `
fragment CartPrices on CartPrices {
  subtotal_with_discount_excluding_tax {
    value
    currency
  }
  subtotal_excluding_tax {
    value
    currency
  }
  subtotal_including_tax {
    value
    currency
  }
  applied_taxes {
    amount {
      value
      currency
    }
    label
  }
  discounts {
    amount {
      value
      currency
    }
    label
  }
  grand_total {
    value
    currency
  }
  cash_on_delivery_fee {
    currency
    value
  }
}
`;
