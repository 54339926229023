import { ProductAttributeFilterInput } from '~/modules/GraphQL/types';
import type { ComposableFunctionArgs } from '~/composables';
import type { FacetSearchParams } from '../useFacet';

export const groupedRangeFilters = [
  'azot',
  'bor',
  'bar',
  'cobalt',
  'cynk',
  'chelat_idha',
  'chelat_edta',
  'glicyna',
  'jod',
  'jod_calkowity',
  'fosfor',
  'krzem',
  'kwasy_humusowe',
  'dwutlenek_krzemu',
  'tytan',
  'tlenek_wapnia',
  'trojtlenek_siarki',
  'potas',
  'selen',
  'molibden',
  'magnez',
  'mangan',
  'miedz',
  'wapn',
  'wegiel_organiczny',
  'wegiel_organiczny_w_suchej_masie',
  'zelazo',
];
export const rangeFilters = [
  'price',
  'weight_decimal',
  'volume_decimal',
  ...groupedRangeFilters,
];

// eslint-disable-next-line unicorn/prefer-set-has
const dateRangeFilters = [
  'new',
];

export function createProductAttributeFilterInput(params: ComposableFunctionArgs<FacetSearchParams>): ProductAttributeFilterInput {
  const attributeFilter : Record<string,
  { from?: string, to?: string }
  | { from: number, to: number }
  | { eq: unknown }
  | { in: unknown }> = {};
  const inputFilters = params?.filters ?? {};

  const categoryFilter = {
    category_uid: { in: [params.category_uid, ...inputFilters.category_uid ?? []] },
  };

  Object.keys(inputFilters).forEach((key: string) => {
    if (dateRangeFilters.includes(key)) {
      const rangeFrom = { to: '' };
      const rangeTo = { from: '' };
      const flatValue = inputFilters[key]
        .flatMap((inputFilter) => inputFilter.split('_'));

      [rangeTo.from] = flatValue;
      rangeFrom.to = flatValue[flatValue.length - 1];
      attributeFilter.news_from_date = rangeFrom;
      attributeFilter.news_to_date = rangeTo;
    } else if (rangeFilters.includes(key)) {
      const range = { from: 0, to: 0 };
      const flatValue = inputFilters[key]
        .flatMap((inputFilter) => inputFilter.split('_'))
        .map((str: string) => Number.parseFloat(str))
        .sort((a, b) => a - b);

      [range.from] = flatValue;
      range.to = flatValue[flatValue.length - 1];

      attributeFilter[key] = range;
    } else if (typeof inputFilters[key] === 'string') {
      attributeFilter[key] = { eq: inputFilters[key] };
    } else if (inputFilters[key].length === 1) {
      attributeFilter[key] = { eq: inputFilters[key][0] };
    } else {
      attributeFilter[key] = { in: inputFilters[key] };
    }
  });

  return { ...attributeFilter, ...categoryFilter };
}
