import { Middleware } from '@nuxt/types';
import { usePageStore } from '~/stores/page';
import { Logger } from '~/helpers/logger';
import { RoutableInterface } from '~/modules/GraphQL/types';
import routeGql from '~/modules/GraphQL/customQueries/route.gql';
const urlResolverMiddleware : Middleware = async (context) => {
  // const pageStore = usePageStore();
  const { path } = context.route;

  const clearUrl = path.replace(/[a-z]+\/[cp|]\//gi, '').replace(`/${context.i18n.locale}`, '');

  Logger.debug('middleware/url-resolver', clearUrl);

  try {
    const { data, errors } = await context.app.$vsf.$magento.api.customQuery({
      query: routeGql,
      queryVariables: {
        url: clearUrl,
      },
    });
    // context.app.$vsf.$magento.api.route(clearUrl);

    Logger.debug('middleware/url-resolver/result', { data, errors });

    const results: RoutableInterface | null = (data as any)?.route ?? null;

    if (!results || errors?.length) {
      context.error({ statusCode: 404 });
      return
    }
    const isRedirect = results?.redirect_code === 301 || results?.redirect_code === 302;
    if (isRedirect && results?.relative_url) {
      context.redirect(results?.redirect_code, `/${results.relative_url}`);
      return
    }
    context.app.routeData = results
    // pageStore.$patch((state) => {
    //   state.routeData = results;
    // });
  } catch (error) {
    Logger.error('middleware/url-resolver error', error)
    context.error({ statusCode: 500, message: 'Internal Server Error' })
  }
};

export default urlResolverMiddleware;
