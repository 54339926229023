export default `
query getAmOrderList {
  amOrderList {
    attribute
    id
    text
    sortDirection
  }
}
`;
