import { ProductAttributeSortInput } from '~/modules/GraphQL/types';

export function createProductAttributeSortInput(sortData: string): ProductAttributeSortInput {
  // eslint-disable-next-line
  let baseData = sortData.split(/_/gi);
  const lastElement = baseData.pop();

  return baseData.length > 0
    ? { [baseData.join('_')]: lastElement }
    : {};
}
