export default {
  googleTagManager: {
    product_attributes: [
      'name', 'id', 'sku', {
        priceInclTax: 'price',
      }, {
        qty: 'quantity',
      },
    ],
    trackEdrone: false,
    trackTax: true,
    idParam: 'parentSku',
    backendEcommerce: false,
  },
};
