export default `
  query getProductsAggregations($filter: ProductAttributeFilterInput) {
    products(filter: $filter) {
      aggregations {
        label
        count
        attribute_code
        options {
          count
          label
          value
          __typename
        }
        position
        __typename
      }
      __typename
    }
  }
`;
