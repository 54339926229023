/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable default-case */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import {
  GATracker,
  ProductInList,
  ProductInCart,
  ImpressionArgs,
  DetailArgs,
  ClickArgs,
  PromotionViewArgs,
  PromotionClickArgs,
  AddToCartArgs,
  RemoveFromCartArgs,
  PurchaseArgs,
  CheckoutArgs,
  PageViewArgs,
  AddToWishlistArgs,
  RemoveFromWishlistArgs,
  Optional,
  ProductProcessing,
  UserFormArgs,
} from './interfaces';
import {
  getDL,
  getProductCategories,
  getProductIds,
  getProductPrices,
  patchObject,
  getGaConfig,
  getCurrency,
  adapterUserAddress,
  getLanguage,
} from './common';
import { addPrefixToKeys } from './helpers';

export class GA implements GATracker {
  cleanup() {
    const dl = getDL();
    dl.push({
      v: null,
      items: null,
      eventCategory: null,
      eventAction: null,
    });
  }

  pageView({
    url,
    title,
    type,
    viewName,
    user,
    otherGlobal,
  }: PageViewArgs): void {
    const dl = getDL();
    const res = {} as Record<string, any>;

    res.event = 'pageview';
    res.v = 4;
    res['page-location'] = url;
    res['page-title'] = title;
    res['content-type'] = type;
    res['content-view-name'] = viewName;
    res.language = getLanguage();
    if (user) {
      const address = adapterUserAddress(user);
      res['user-id'] = user.id;
      res['user-name'] = user.firstname;
      res['user-surname'] = user.lastname;
      res['user-email'] = user.email;
      res['user-city'] = address.city;
      res['user-country'] = address.country;
    }

    patchObject(res, otherGlobal);
    this.cleanup();
    dl.push(res);
  }

  userForm({
    name,
    user,
    consents,
    otherGlobal,
  }: UserFormArgs): void {
    const dl = getDL();
    const res = {
      event: 'userform',
      eventAction: 'Form Sent',
      eventCategory: name,
    } as Record<string, any>;

    res.extension_attributes = consents;
    res.language = getLanguage();
    res.v = 4;
    if (user) {
      const address = adapterUserAddress(user);
      res['user-id'] = user.id;
      res['user-name'] = user.firstname;
      res['user-surname'] = user.lastname;
      res['user-email'] = user.email;

      res['user-city'] = address.city;
      res['user-country'] = address.country;
    }
    patchObject(res, otherGlobal);
    this.cleanup();
    dl.push(res);
  }

  attachCategories(category: string, product: Record<string, any>): object {
    if (!category) return product;
    const categories = category.split('/');
    const extendedProduct = product;
    for (let i = 0; (i < categories.length) && (i < 5); i++) {
      extendedProduct[`item_category${i ? `${i + 1}` : ''}`] = categories[i];
    }
    return extendedProduct;
  }

  paymentType(method?: string): string | undefined {
    if (!method) return undefined;
    return method;
  }

  shippingTier(method?: string): string | undefined {
    if (!method) return undefined;
    return method;
  }

  mapProduct(product: ProductInList & ProductInCart, how: ProductProcessing = ProductProcessing.Regular): object {
    const {
      id, dbId, parentDbId, childSku, parentSku,
    } = getProductIds(product);
    const { tax, price } = getProductPrices(product);
    const trackEdrone = getGaConfig('trackEdrone', false);
    const { category, product_category_ids, product_category_names } = getProductCategories(product, !!trackEdrone);
    let res = {
      item_id: id,
      item_name: product.name,
      currency: product.currency || getCurrency(),
      discount: 0, // TODO
      item_brand: product.brand,
      item_variant: undefined, // TODO
      tax,

      // non parsable by google, usable in other tags
      dbId,
      parentDbId,
      childSku,
      parentSku,
    } as Record<string, any>;

    if (price) {
      res.price = price;
    }
    if (product.affiliation) {
      res.affiliation = product.affiliation;
    }
    if (product?.is_in_stock !== undefined) {
      res.is_in_stock = product.is_in_stock;
    }
    if (how === ProductProcessing.List) {
      if (product.list) {
        res.item_list_id = product.list;
        res.item_list_name = product.list;
      }
      if (product.position) {
        res.index = product.position;
      }
    }
    if (how === ProductProcessing.InCart) {
      if (product.qty) {
        res.quantity = product.qty;
      }
      if (product.coupon) {
        res.coupon = product.coupon;
      }
    }
    this.attachCategories(category, res);
    if (trackEdrone) {
      res.product_category_ids = product_category_ids;
      res.product_category_names = product_category_names;
    }
    if (product.ga_fields) {
      res = Object.assign(res, product.ga_fields);
    }
    return res;
  }

  extractPromo(
    {
      banner,
      creative_name,
      creative_slot,
      promotion_id,
      promotion_name,
      otherGlobal,
      otherItem,
    }: PromotionViewArgs | PromotionClickArgs,
    eventName: string,
  ): object {
    const id = promotion_id || (`${banner.id}`);
    const name = promotion_name || creative_name || banner.text1;
    const pos = creative_slot || (banner.order || '1');
    const creative = creative_name || banner.urlDesktop || banner.urlMobile;
    const item = {
      item_id: banner.alt || id,
      item_name: name,
    };
    const ecommerce = {
      v: 4,
      event: eventName,
      creative_name: creative,
      creative_slot: pos,
      promotion_id: id,
      promotion_name: name,
      promo_start_date: banner.from,
      promo_end_date: banner.to,
      items: [item],
    };
    patchObject(ecommerce, otherGlobal);
    patchObject(item, otherItem);

    return ecommerce;
  }

  impressions({
    impressions,
    otherGlobal,
  }: ImpressionArgs): void {
    const dl = getDL();
    const ecommerceItems = impressions.map((x) => this.mapProduct(x, ProductProcessing.List));
    let ecommerce = {};

    ecommerce = {
      v: 4,
      event: 'view_item_list',
      eventAction: 'Impressions',
      eventCategory: 'Ecommerce GA4',
      currency: getCurrency(),
      items: ecommerceItems,
    };
    if (typeof otherGlobal !== 'undefined') {
      patchObject(ecommerce, otherGlobal);
    }
    this.cleanup();
    dl.push(ecommerce);
  }

  click({
    item,
    otherGlobal,
  }: ClickArgs): void {
    const dl = getDL();
    const ecommerceItem = this.mapProduct(item, ProductProcessing.List);
    const ecommerce = {
      v: 4,
      event: 'select_item',
      eventAction: 'Product Click',
      eventCategory: 'Ecommerce GA4',
      currency: getCurrency(),
      items: [ecommerceItem],
    };
    patchObject(ecommerce, otherGlobal);
    this.cleanup();
    dl.push(ecommerce);
  }

  promo(args: PromotionViewArgs): void {
    const dl = getDL();

    this.cleanup();
    dl.push(this.extractPromo(args, 'view_promotion'));
  }

  promoClick(args: PromotionClickArgs): void {
    const dl = getDL();
    this.cleanup();
    dl.push(this.extractPromo(args, 'select_promotion'));
  }

  detail({
    product,
    otherGlobal,
  }: DetailArgs): void {
    const dl = getDL();
    const ecommerceItem = this.mapProduct(product, ProductProcessing.Regular);
    const ecommerce = {
      v: 4,
      event: 'view_item',
      eventAction: 'Product Detail',
      eventCategory: 'Ecommerce GA4',
      currency: getCurrency(),
      items: [ecommerceItem],
    };
    patchObject(ecommerce, otherGlobal);
    this.cleanup();
    dl.push(ecommerce);
  }

  configurable_detail({
    product,
    otherGlobal,
  }: DetailArgs): void {
    const dl = getDL();
    const ecommerceItem = this.mapProduct(product, ProductProcessing.Regular);
    const configurableEcommerceItem = addPrefixToKeys(ecommerceItem, 'configurable_');
    const ecommerce = {
      v: 4,
      event: 'configurable_view_item',
      eventAction: 'Configurable Product Detail',
      eventCategory: 'Ecommerce GA4',
      currency: getCurrency(),
      ...configurableEcommerceItem,
    };
    patchObject(ecommerce, otherGlobal);
    this.cleanup();
    dl.push(ecommerce);
  }

  addToCart({
    product,
    otherGlobal,
  }: AddToCartArgs): void {
    const dl = getDL();
    const ecommerceItem = this.mapProduct(product, ProductProcessing.InCart);
    const ecommerce = {
      v: 4,
      event: 'add_to_cart',
      eventAction: 'Add to Cart',
      eventCategory: 'Ecommerce GA4',
      currency: getCurrency(),
      items: [ecommerceItem],
    };
    patchObject(ecommerce, otherGlobal);

    this.cleanup();
    dl.push(ecommerce);
  }

  removeFromCart({
    product,
    otherGlobal,
  }: RemoveFromCartArgs): void {
    const dl = getDL();
    const ecommerceItem = this.mapProduct(product, ProductProcessing.InCart);
    const ecommerce = {
      v: 4,
      event: 'remove_from_cart',
      eventAction: 'Remove from Cart',
      eventCategory: 'Ecommerce GA4',
      currency: getCurrency(),
      items: [ecommerceItem],
    };
    patchObject(ecommerce, otherGlobal);

    this.cleanup();
    dl.push(ecommerce);
  }

  wishlist({
    product,
    list,
    otherItem,
    otherGlobal,
  }: AddToWishlistArgs): void {
    const dl = getDL();
    const ecommerceItem = this.mapProduct(product, ProductProcessing.Regular);
    const ecommerce = {
      v: 4,
      event: 'add_to_wishlist',
      eventAction: 'Add To wishlist',
      eventCategory: 'Ecommerce GA4',
      currency: getCurrency(),
      wishlist: list,
      items: [ecommerceItem],
    };
    patchObject(ecommerce, otherGlobal);
    patchObject(ecommerceItem, otherItem);
    this.cleanup();
    dl.push(ecommerce);
  }

  wishlistRemove({
    product,
    list,
    otherItem,
    otherGlobal,
  }: RemoveFromWishlistArgs): void {
    const dl = getDL();
    const ecommerceItem = this.mapProduct(product, ProductProcessing.Regular);
    const ecommerce = {
      v: 4,
      event: 'remove_from_wishlist',
      eventAction: 'Remove From wishlist',
      eventCategory: 'Ecommerce GA4',
      currency: getCurrency(),
      wishlist: list,
      items: [ecommerceItem],
    };
    patchObject(ecommerce, otherGlobal);
    patchObject(ecommerceItem, otherItem);
    this.cleanup();
    dl.push(ecommerce);
  }

  push(obj: Optional): void {
    const dl = getDL();
    const extendedObj = { ...obj };
    extendedObj.v = '4';
    extendedObj.event = obj.event || 'custom_event';
    this.cleanup();
    dl.push(extendedObj);
  }

  sign({ type, method = 'native', userId = null }: { type: 'sign_up' | 'login' | 'other', method: string, userId: string }): void {
    const dl = getDL();
    const eventObject = {
      v: 4,
      event: type,
      eventAction: `User ${type}`,
      eventCategory: type,
      method,
      user_id: userId,
    };
    this.cleanup();
    dl.push(eventObject);
  }

  checkout({
    products,
    step,
    method,
    otherGlobal,
    otherCheckout,
  }: CheckoutArgs): void {
    const dl = getDL();
    const ecommerceItems = products.map((x) => this.mapProduct(x, ProductProcessing.InCart));
    const ecommerce = {
      v: 4,
      eventCategory: 'Ecommerce GA4',
      currency: getCurrency(),
      items: ecommerceItems,
    } as Record<string, any>;
    const ecommerceExtended = {
      v: 4,
      event: 'checkout_progress',
      checkout_step: step,
      eventCategory: 'Ecommerce GA4 (Extended)',
      eventAction: 'Checkout Progress',
      currency: getCurrency(),
      items: ecommerceItems,
    } as Record<string, any>;
    switch (step) {
      case 1: {
        ecommerce.event = 'view_cart';

        break;
      }
      case 2: {
        ecommerce.event = 'begin_checkout';

        break;
      }
      case 4: {
        // shipping
        ecommerce.event = 'add_shipping_info';
        ecommerce.shipping_tier = this.shippingTier(method);

        break;
      }
      case 5: {
        // payment
        ecommerce.event = 'add_payment_info';
        ecommerce.payment_type = this.paymentType(method);

        break;
      }
      // No default
    }
    patchObject(ecommerce, otherGlobal);
    patchObject(ecommerceExtended, otherCheckout);
    if (ecommerce.event) {
      this.cleanup();
      dl.push(ecommerce);
    }
    this.cleanup();
    dl.push(ecommerceExtended);
  }

  purchase({
    cartTotals,
    orderDetails,
    products,
    otherGlobal,
    otherPurchase,
  }: PurchaseArgs): void {
    const dl = getDL();
    const ecommerceItems = products.map((x) => this.mapProduct(x, ProductProcessing.InCart));
    const dbOrderId = (getGaConfig('dbOrderId', false) as boolean);
    const orderId = dbOrderId && orderDetails.order_id ? orderDetails.order_id : orderDetails.order_increment_id;
    const backendEcommerce = (getGaConfig('backendEcommerce', true) as boolean);
    const trackTax = (getGaConfig('trackTax', false) as boolean);
    const trackShipping = (getGaConfig('trackShipping', true) as boolean);
    const ecommerce = {
      v: 4,
      event: 'purchase',
      eventAction: 'Purchase',
      eventCategory: 'Ecommerce GA4',
      transaction_id: orderId,
      currency: getCurrency(),
      items: ecommerceItems,
    } as Record<string, any>;
    const ecommerceExtended = {
      v: 4,
      event: 'purchase_for_tags',
      eventCategory: 'Purchase for Tags',
      eventAction: 'Purchase',
      transaction_id: orderId,
      currency: getCurrency(),
      items: ecommerceItems,
    } as Record<string, any>;
    const totals = {} as Record<string, any>;

    cartTotals.forEach((item) => {
      totals[item.code] = item.value;
    });

    if (trackTax) {
      ecommerce.tax = totals.tax;
      ecommerceExtended.tax = totals.tax;
    }
    ecommerce.value = totals.grand_total;
    ecommerceExtended.value = totals.grand_total;

    if (trackShipping) {
      let shipping = totals.shipping || 0;
      shipping += totals.cash_on_delivery_fee || 0;

      ecommerce.shipping = shipping;
      ecommerceExtended.shipping = shipping;
    }

    patchObject(ecommerce, otherGlobal);
    patchObject(ecommerceExtended, otherGlobal);

    patchObject(ecommerce, otherPurchase);
    patchObject(ecommerceExtended, otherPurchase);

    if (!backendEcommerce) {
      this.cleanup();
      dl.push(ecommerce);
    }
    this.cleanup();
    dl.push(ecommerceExtended);
  }
}
