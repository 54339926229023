export const optimizedProductFieldsString = `
  __typename
  id
  uid
  sku
  stock_status
  name
  new_from_date
  new_to_date
  product_withdrawn
  manufacturer
  bestseller
  invoice_only
  estimated_avalibility
  delivery_time
  order_number
  doradca
  main_category
  rodzaj_srodka_ochrony
  certyfikat_eko
  info_label

  stock_item {
    qty_increments
    min_sale_qty
    max_sale_qty
  }
  thumbnail {
    url
    position
    disabled
    label
  }
`;

export default `
  #graphql
  fragment OptimizedProductFields on ProductInterface {
    ${optimizedProductFieldsString}
    categories {
      id
      name
      level
      path
    }
  }
`;
