import { Plugin } from '@nuxt/types';

declare module 'vue/types/vue' {
  interface Vue {
    $isPWA: boolean;
  }
}

const pwaCheckPlugin: Plugin = (context, inject) => {
  if (process.client) {
    // @ts-ignore
    const isPWA = window.navigator.standalone || window.matchMedia('(display-mode: standalone)').matches;
    inject('isPWA', isPWA);
  }
};

export default pwaCheckPlugin;
