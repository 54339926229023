import { Logger } from '~/helpers/logger';
import { Cart } from '~/modules/GraphQL/types';
import { VsfContext } from '~/composables/context';
import applyCouponToCart from '~/modules/GraphQL/customQueries/applyCouponToCart.gql';

export const applyCouponCommand = {
  execute: async (context: VsfContext, {
    currentCart,
    couponCode,
    customQuery = { applyCouponToCart: 'applyCouponToCart' },
    customHeaders = {},
  }) => {
    Logger.debug('[Magento]: Apply coupon on cart', {
      couponCode,
      currentCart,
    });

    const input = {
      input: {
        cart_id: currentCart.id,
        coupon_code: couponCode,
      },
    };
    const { data, errors } = await context.$magento.api.customQuery({
      query: applyCouponToCart,
      queryVariables: input,
    });

    Logger.debug('[Result]:', { data, errors });
    // @ts-ignore
    const responseData = data.applyCouponToCart?.cart as unknown as Cart;

    return {

      updatedCart: responseData,
      updatedCoupon: { code: couponCode },
      errors,
    };
  },
};
