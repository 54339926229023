import { Plugin } from '@nuxt/types';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import customer from '~/modules/GraphQL/customQueries/customer.gql';

const loginPushPlugin: Plugin = async ({ $pinia, app }) => {
  const customerStore = useCustomerStore($pinia);
  if (customerStore.isLoggedIn) {
    const { data } = await app.context.$vsf.$magento.api.customQuery({ query: customer });
    const user = data?.customer ?? {};
    const userId = user?.id;
    app.$googleTagManager.sign({
      type: 'auto login',
      userId,
    });
  }
};

export default loginPushPlugin;
