/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  computed,
  ref,
  useRouter,
  useContext,
  onMounted,
} from '@nuxtjs/composition-api';
import { debounce } from 'lodash-es';
import cartGetters from '~/modules/checkout/getters/cartGetters';
import {
  useUiNotification,
  useExternalCheckout,
  useImage,
  useProduct,
} from '~/composables';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useUser } from '~/modules/customer/composables/useUser';
import type { UseCartViewInterface } from '~/modules/checkout/composables/useCartView/useCartView';
import type { ConfigurableCartItem, BundleCartItem, CartItemInterface } from '~/modules/GraphQL/types';
import { ProductStockStatus } from '~/modules/GraphQL/types';
import { useOmnibusPrice } from '~/modules/catalog/product/composables/useOmnibusPrice';

/**
 * Allows loading and manipulating cart view.
 *
 * See the {@link UseCartViewInterface} for a list of methods and values available in this composable.
 */
export function useCartView(): UseCartViewInterface {
  const { localePath } = useContext();
  const { initializeCheckout } = useExternalCheckout();
  const { getMagentoImage, imageSizes } = useImage();
  const router = useRouter();
  const { getProductPath } = useProduct();
  const { getOmnibusPrices, omnibusPrices } = useOmnibusPrice();
  const {
    cart,
    removeItem,
    updateItemQty,
    load: loadCart,
    loading,
  } = useCart();
  const { isAuthenticated } = useUser();
  const { send: sendNotification, notifications } = useUiNotification();

  const products = computed(() => cartGetters
    .getItems(cart.value)
    .filter(Boolean)
    .map((item) => {
      const { sku: currentProductSku } = cartGetters.isProductConfigurable(item)
        ? cartGetters.getConfiguredVariant(item as ConfigurableCartItem)
        : item.product;
      return {
        ...item,
        product: {
          ...item.product,
          ...[(item as ConfigurableCartItem).configured_variant ?? {}],
          original_sku: item.product.sku,
          invoice_only: item.product.invoice_only,
          omnibus_price: omnibusPrices?.value ? omnibusPrices.value[currentProductSku] : '',
        },
      };
    }));
  const totals = computed(() => cartGetters.getTotals(cart.value));
  const discount = computed(() => -cartGetters.getDiscountAmount(cart.value));
  const totalItems = computed(() => cartGetters.getTotalItems(cart.value));
  const getAttributes = (product: ConfigurableCartItem) => product.configurable_options || [];
  const getBundles = (product: BundleCartItem) => product.bundle_options?.map((b) => b.values).flat() || [];
  const isRemoveModalVisible = ref(false);
  const itemToRemove = ref<CartItemInterface>();
  const isInvoiceOnly = computed(() => products.value.some((product) => product.product.invoice_only));

  onMounted(async () => {
    // TODO: Check if this is necessary `?` - otherwise it will crash on the first render.
    if (!cart.value?.id) {
      await loadCart();
      const productsInCart = cartGetters.getItems(cart.value).filter(Boolean).map((item) => ({ ...item.product }));
      // @ts-ignore
      await getOmnibusPrices(productsInCart, 'all');
    }
  });

  const goToCheckout = async () => {
    const redirectUrl = initializeCheckout({ baseUrl: '/checkout/user-account' });
    await router.push(localePath(redirectUrl));
  };

  const showRemoveItemModal = ({ product }: { product: CartItemInterface }) => {
    if (notifications.value.length > 0) {
      notifications.value[0].dismiss();
    }

    isRemoveModalVisible.value = true;
    itemToRemove.value = product;
  };

  const removeItemAndSendNotification = async (product: CartItemInterface) => {
    await removeItem({ product });
    isRemoveModalVisible.value = false;

    sendNotification({
      id: Symbol('product_removed'),
      message: `${cartGetters.getItemName(product)} został pomyślnie usunięty z koszyka`,
      type: 'success',
      icon: 'check',
      persist: false,
      title: 'Product removed',
    });
    // await loadCart();
  };

  const removeAllItems = async () => {
    for (const product of products.value) {
      // eslint-disable-next-line no-await-in-loop
      await removeItem({ product });
    }

    sendNotification({
      id: Symbol('products_removed'),
      message: 'Wszystkie produkty zostały pomyślnie usunięte z koszyka.',
      type: 'success',
      icon: 'check',
      persist: false,
      title: 'Products removed',
    });
  };

  const delayedUpdateItemQty = debounce(
    (params) => updateItemQty(params),
    500,
  );

  const isInStock = (product: CartItemInterface) => cartGetters.getStockStatus(product) === ProductStockStatus.InStock;

  return {
    showRemoveItemModal,
    removeAllItems,
    removeItemAndSendNotification,
    sendNotification,
    delayedUpdateItemQty,
    goToCheckout,
    getAttributes,
    getBundles,
    isInStock,
    getMagentoImage,
    getProductPath,
    loading,
    isAuthenticated,
    products,
    isRemoveModalVisible,
    itemToRemove,
    totals,
    totalItems,
    imageSizes,
    discount,
    cartGetters,
    isInvoiceOnly,
  };
}

export default useCartView;
export * from './useCartView';
