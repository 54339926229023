import { PriceTiersWithCustomerGroup, ProductInterface } from '~/modules/GraphQL/types';

export const getPriceTiersWithCustomerGroup = (product: ProductInterface): PriceTiersWithCustomerGroup[] => {
  if (!product?.price_tiers_with_customer_group) {
    return [];
  }
  return product.price_tiers_with_customer_group;
};

export const getPriceForLoggedCustomer = (product: ProductInterface): PriceTiersWithCustomerGroup | null => {
  const priceTiers = getPriceTiersWithCustomerGroup(product)
    .filter((tier) => tier.customer_group_id === '1');
  const isPriceForLoggedCustomer = priceTiers.some((tier) => tier.customer_group_id === '1');
  if (priceTiers.length === 0 || priceTiers.length > 1 || !isPriceForLoggedCustomer) {
    return null;
  }
  return priceTiers[0];
};

export const getPriceThreshold = (product: ProductInterface): PriceTiersWithCustomerGroup[] => {
  const priceTiers = getPriceTiersWithCustomerGroup(product)
    .filter((tier) => tier.customer_group_id !== '1');
  const isQuantityThreshold = priceTiers.some((tier) => tier.quantity > 1);
  if (priceTiers.length === 0 && !isQuantityThreshold) {
    return [];
  }
  return priceTiers;
};

export const isTableForCustomerGroupPrice = (product: ProductInterface) => getPriceThreshold(product).length > 0;
export const isLoggedCustomerGroupPrice = (product: ProductInterface) => getPriceForLoggedCustomer(product) !== null;
