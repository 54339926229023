import { UseContextReturn } from '~/types/core';
import type { GetProductSearchParams } from '~/modules/catalog/product/types';
import productsListGql from '../productsList.gql';

export const getProductListCommand = {
  execute: async (context: UseContextReturn, searchParams: GetProductSearchParams) => {
    const { data } = await context.app.$vsf.$magento.api.customQuery({
      query: productsListGql,
      queryVariables: searchParams,
    });
    // @ts-ignore
    return data?.products ?? null;
  },
};
