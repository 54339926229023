/** GraphQL Mutation enroll the user to be notified when an product becomes available */
export default `
  mutation($productId: String!) {
    CustomerNotifyInStock(product_id: $productId) {
      customer_email
      customer_id
      price
      product_id
      store_id
      website_id
    }
  }
`;
