import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import placeOrder from '~/modules/GraphQL/customQueries/placeOrder.gql';
import useCart from '~/modules/checkout/composables/useCart';
import type { PlaceOrderOutput } from '~/modules/GraphQL/types';
import { ComposableFunctionArgs, useUiNotification } from '~/composables';
import type { UseMakeOrderErrors, UseMakeOrderInterface } from './useMakeOrder';

/**
 * Allows making an order from a cart.
 *
 * See the {@link UseMakeOrderInterface} for a list of methods and values available in this composable.
 */
export function useMakeOrder(): UseMakeOrderInterface {
  const loading = ref(false);
  const error = ref<UseMakeOrderErrors>({ make: null });
  const { cart } = useCart();
  const { app } = useContext();
  const { send: sendNotification } = useUiNotification();

  const make = async (params?: ComposableFunctionArgs<{}>): Promise<PlaceOrderOutput | null> => {
    Logger.debug('useMakeOrder.make');
    let placedOrder = null;
    try {
      loading.value = true;
      const input = {
        input: {
          cart_id: cart.value.id,
        },
      };
      const { data, errors } = await app.$vsf.$magento.api.customQuery({
        query: placeOrder,
        queryVariables: input,
      });
      // @ts-ignore
      placedOrder = data?.placeOrder ?? null;
      // @ts-ignore
      if (errors && errors.length > 0) {
        sendNotification({
          id: Symbol('order-process-failed'),
          message: app.i18n.t(errors[0].message) as string,
          type: 'success',
          icon: 'check',
          persist: false,
          title: 'Checkout',
        });
      }
      // placedOrder = await placeOrderCommand.execute(
      //   context,
      //   cart.value.id,
      //   params,
      // );
      error.value.make = null;
    } catch (err) {
      error.value.make = err;
      Logger.error('useMakeOrder.make', err);
    } finally {
      loading.value = false;
    }

    return placedOrder;
  };

  return {
    make,
    error: readonly(error),
    loading: readonly(loading),
  };
}

export * from './useMakeOrder';
export default useMakeOrder;
