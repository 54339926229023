export enum PageTypeEnum {
  CATEGORY = 'CATEGORY',
  SEARCH = 'SEARCH',
}

export const getSortingValue = (sortingOption: any) => `${sortingOption.attribute}_${sortingOption.sortDirection}`;

export const hydrateSortingOptions = (options: any[]) => options.map((option: any) => ({
  label: option.attribute === 'position' ? 'Domyślnie' : option.text,
  value: getSortingValue(option),
}));

export const definePageType = (isSearch: boolean) => (isSearch ? PageTypeEnum.SEARCH : PageTypeEnum.CATEGORY);
