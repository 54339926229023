const fragmentCategory = `
  fragment CategoryFields on CategoryTree {
    id
    is_anchor
    name
    image
    mobile_banner_image
    category_popular_sku
    product_count
    children_count
    uid
    url_path
    url_suffix
    include_in_menu
    category_banner_link
  }
`;

export default `
  query categoryList($filters: CategoryFilterInput) {
    categoryList(filters: $filters) {
      ...CategoryFields
      children {
        ...CategoryFields
      }
    }
  }
  ${fragmentCategory}
`;
