import { UseContextReturn } from '~/types/core';
import type { GetProductSearchParams } from '~/modules/catalog/product/types';
import productsListPreviewGql from '~/modules/GraphQL/customQueries/productListPreview.gql';

export const getProductListPreviewCommand = {
  execute: async (context: UseContextReturn, searchParams: GetProductSearchParams) => {
    const { data } = await context.app.$vsf.$magento.api.customQuery({
      query: productsListPreviewGql,
      queryVariables: searchParams,
    });
    // @ts-ignore
    return data?.products ?? null;
  },
};
