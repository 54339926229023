import { Middleware } from '@nuxt/types';
import { useStoryblokApi } from '@storyblok/nuxt-2';
import { Logger } from '~/helpers/logger';

const urlResolverMiddleware : Middleware = async (context) => {
  const storyblokApi = useStoryblokApi();
  const { path } = context.route;

  Logger.debug('middleware/story-url-resolver', path);

  try {
    // TODO: @Dima @George @Olek - context problem
    const { data } = await storyblokApi.get(`cdn/stories/${path}`, {
      version: context.env.VSF_STORYBLOK_VERSION,
    });
    const results = (data as any)?.story ?? null;
    Logger.debug('middleware/story-url-resolver/result', { data });

    context.app.currentStory = results;
  } catch (error) {
    console.log('error', error);
    if (typeof error === 'string') {
      const parsedError = JSON.parse(error as string);
      context.error({ statusCode: parsedError.status ?? 500 });
    } else {
      context.error({ statusCode: 500 });
    }
  }
};

export default urlResolverMiddleware;
