export default `
  query route($url: String!) {
    route(url: $url) {
      type
      redirect_code
      relative_url
       ... on ProductInterface {
        sku
      }
      ... on CategoryTree {
        uid
      }
      ... on CmsPage {
        identifier
      }
      ... on CategoryInterface {
        uid
      }
    }
  }
`;