import { UseContextReturn } from '~/types/core';
import type { GetProductSearchParams } from '~/modules/catalog/product/types';
import productDetailsGql from '../productDetailsQuery.gql';

export const getProductDetailsCommand = {
  execute: async (context: UseContextReturn, searchParams: GetProductSearchParams) => {
    const { data } = await context.app.$vsf.$magento.api.customQuery({
      query: productDetailsGql,
      queryVariables: searchParams,
    });
    // @ts-ignore
    return data?.products ?? null;
  },
};
