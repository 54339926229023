import { useContext } from '@nuxtjs/composition-api';
import { UseImageInterface } from './useImage';

const imageSizes = {
  ogImage: {
    width: 1200,
    height: 630,
  },
  productCard: {
    desktop: {
      width: 250,
      height: 250,
    },
    tablet: {
      width: 200,
      height: 200,
    },
    mobile: {
      width: 200,
      height: 200,
    },
  },
  sidebar: {
    desktop: {
      width: 110,
      height: 130,
    },
    tablet: {
      width: 104,
      height: 104,
    },
    mobile: {
      width: 104,
      height: 104,
    },
  },
  productCardHorizontal: {
    // TODO: do usunięcia, ale zostawiamy bo daję błędy (CategoryProductList.vue)
    width: 140,
    height: 200,
  },
  productGallery: {
    thumb: {
      width: 98,
      height: 98,
    },
    image: {
      desktop: {
        width: 567,
        height: 567,
      },
      tablet: {
        width: 240,
        height: 240,
      },
      mobile: {
        width: 240,
        height: 240,
      },
    },
  },
  cart: {
    width: 205,
    height: 150,
  },
  categoryBanner: {
    desktop: {
      width: 1489,
      height: 212,
    },
    mobile: {
      width: 375,
      height: null,
    },
  },
  orderTile: {
    width: 118,
    height: 68,
  },
};

const baseImageConfig = {
  background: 'white', fit: 'contain', format: 'webp', quality: '100',
};
const baseImageConfigQuery = '/_ipx/f_webp,b_white,fit_contain,quality_100,s_';

/**
 * Allows extracting image paths from magento URL.
 *
 * See the {@link UseImageInterface} for a list of methods and values available in this composable.
 * */
export function useImage(): UseImageInterface {
  const context = useContext();
  /**
   * Extract image path from Magento URL.
   *
   * @param fullImageUrl {string | null}
   *
   * @return {string}
   */
  const getMagentoImage = (fullImageUrl: string | null) => {
    if (!fullImageUrl) return '';

    // @ts-ignore
    const { imageProvider, magentoBaseUrl } = context.$vsf.$magento.config;

    if (imageProvider !== 'ipx') {
      const url = fullImageUrl.split(`${magentoBaseUrl}`);

      const regex = /cache\/(.*?)\//gm;
      return url[1].replace(regex, '');
    }

    if (imageProvider === 'ipx') {
      const { $config } = context;

      for (const alias in $config.imageAlias) {
        if (fullImageUrl.includes($config.imageAlias[alias])) return fullImageUrl.replace($config.imageAlias[alias], alias);
      }

      return fullImageUrl;
    }
  };
  /**
   * Extracts image path from Magento URL with cdn config.
   *
   * @param fullImageUrl {string | null}
   *
   * @return {string}
   */
  const getMagentoImageWithConfig = (fullImageUrl: string | null) => {
    const magentoImage = getMagentoImage(fullImageUrl);
    return `${baseImageConfigQuery}${imageSizes.ogImage.width}x${imageSizes.ogImage.height}/${magentoImage}`;
  };

  return {
    getMagentoImage,
    getMagentoImageWithConfig,
    baseImageConfig,
    baseImageConfigQuery,
    imageSizes,
  };
}

export * from './useImage';
export default useImage;
