import { readonly, ref } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import useApi from '~/composables/useApi';
import getAmOrderListGql from '~/modules/GraphQL/customQueries/getAmOrderList.gql';
import getAmDefaultSortingGql from '~/modules/GraphQL/customQueries/getAmDefaultSorting.gql';
import { getSortingValue, hydrateSortingOptions, definePageType } from './helpers';

export function useAmastySorting(): any {
  const { query } = useApi();
  const loading = ref(false);
  const result = ref<{
    default: string;
    available: { label: string; value: string }[];
  }>({
    default: '',
    available: [],
  });
  const error = ref(null);

  const fetchDefaultSorting = async (isSearch: boolean = false) => {
    loading.value = true;
    try {
      const defautSortingParams = {
        pageType: definePageType(isSearch),
      };

      const defautSortingRes = await query<{ defaultSorting: any }>(getAmDefaultSortingGql, defautSortingParams);
      const defautSortingValue = getSortingValue(defautSortingRes?.data?.defaultSorting?.[0]) || '';

      result.value.default = defautSortingValue;
    } catch (err) {
      error.value = err;
      Logger.error('useAmasty/fetchDefaultSorting', err);
    }
    loading.value = false;
  };

  const fetchSortingOptions = async () => {
    loading.value = true;
    try {
      const { data } = await query<{ amOrderList: any[] }>(getAmOrderListGql);

      const hydratedOptions = hydrateSortingOptions(data.amOrderList);
      result.value.available = hydratedOptions;
    } catch (err) {
      error.value = err;
      Logger.error('useAmasty/fetchSortingOptions', err);
    }
    loading.value = false;
  };

  return {
    fetchDefaultSorting,
    fetchSortingOptions,
    result,
    error: readonly(error),
    loading: readonly(loading),
  };
}

export default useAmastySorting;
