export default `
query categoriesList($filters: CategoryFilterInput, $pageSize: Int = 20, $sort: CategorySortInput) {
  categoryList(filters: $filters, pageSize: $pageSize, sort: $sort) {
    id
    include_in_menu
    is_anchor
    thumbnail_image
    thumbnail_image_2
    level
    name
    position
    product_count
    uid
    url_path
    url_suffix
    promote_in_megamenu
  }
}
`;
