












































































































import { SfBottomNavigation, SfImage } from '@storefront-ui/vue';
import {
  defineComponent, useRouter, useContext, computed,
} from '@nuxtjs/composition-api';
import { useUiState } from '~/composables/useUiState';
import { useUser } from '~/modules/customer/composables/useUser';
import { addBasePath } from '~/helpers/addBasePath';
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';
import { useCartView } from '~/modules/checkout/composables/useCartView';

const MobileCategorySidebar = () => import('~/modules/catalog/category/components/sidebar/MobileCategorySidebar/MobileCategorySidebar.vue');

export default defineComponent({
  name: 'BottomNavigation',
  components: {
    SfBottomNavigation,
    MobileCategorySidebar,
    SfImage,
  },
  setup() {
    const {
      toggleCartSidebar,
      toggleWishlistSidebar,
      toggleMobileMenu,
      isMobileMenuOpen,
      isLoginModalOpen,
      isWishlistSidebarOpen,
      isCartSidebarOpen,
    } = useUiState();
    const { isAuthenticated } = useUser();
    const router = useRouter();
    const { app } = useContext();
    const wishlistStore = useWishlistStore();
    const { totalItems } = useCartView();
    const isPWA = app.$isPWA;

    const wishlistItemsCount = computed<number>(() => wishlistStore.wishlist?.items_count ?? 0);

    const handleHomeClick = async () => {
      const homePath = app.localeRoute({ name: 'home' });
      await router.push(homePath);
      if (isMobileMenuOpen.value) {
        toggleMobileMenu();
      }
    };

    const toggleWishlistSidebarIfAuth = () => {
      if (isAuthenticated.value) {
        toggleWishlistSidebar();
      } else {
        router.push('/authentication');
      }
    };

    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'customer' }));
      } else {
        router.push('/authentication');
      }
    };

    const loadCategoryMenu = () => {
      toggleMobileMenu();
    };

    return {
      isAuthenticated,
      isMobileMenuOpen,
      isLoginModalOpen,
      isWishlistSidebarOpen,
      isCartSidebarOpen,
      wishlistItemsCount,
      totalItems,
      toggleWishlistSidebarIfAuth,
      toggleCartSidebar,
      toggleMobileMenu,
      loadCategoryMenu,
      handleAccountClick,
      handleHomeClick,
      addBasePath,
      isPWA,
    };
  },
});
