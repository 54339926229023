export default `
  fragment SelectedShippingMethodFields on SelectedShippingMethod {
    carrier_code
    carrier_title
    method_code
    method_title
    amount {
      value
      currency
    }
    price_incl_tax {
      value
      currency
    }
    price_excl_tax {
      value
      currency
    }
    pickup_address {
      firstname
      lastname
      street
      city
      postcode
      country_code
    }
    is_cash_on_delivery
    parcel_locker_no
  }
`;
