import type { Product } from '~/modules/catalog/product/types';
import { isProductConfigurable } from '~/modules/catalog/product/getters/productGetters';

export type ProductAvailableTypes = 'IN_STOCK' | 'PHONE_ORDER' | 'OUT_OF_STOCK' | 'WITHDRAWN';

export interface ProductAvailable {
  status: boolean;
  label: string;
  stock_status: ProductAvailableTypes;
  icon: string;
  color: string;
}

export const availableList = (stock_status: ProductAvailableTypes): ProductAvailable => {
  switch (stock_status) {
    case 'IN_STOCK':
      return {
        status: true,
        stock_status: 'IN_STOCK',
        icon: '/icons/check-green.svg',
        label: 'produkt dostępny',
        color: '#118233',
      };
    case 'PHONE_ORDER':
      return {
        status: true,
        stock_status: 'PHONE_ORDER',
        icon: '/icons/shopping-bag-tile.svg',
        label: 'dostępny na zamówienie',
        color: '#003FD2',
      };
    case 'WITHDRAWN':
      return {
        status: false,
        stock_status: 'WITHDRAWN',
        icon: '/icons/cross-circle-tile-2.svg',
        label: 'wycofano ze sprzedaży',
        color: '#BA2121',
      };
    default:
      return {
        status: false,
        stock_status: 'OUT_OF_STOCK',
        icon: '/icons/cross-circle-tile.svg',
        label: 'chwilowo niedostępny',
        color: '#707070',
      };
  }
};

export const isProductInStock = (product: Product): boolean => {
  if (!product || product.stock_status !== 'IN_STOCK') {
    return false;
  }
  return true;
};
export const isProductOrderNumber = (product: Product): boolean => {
  if (!product || !product.order_number) {
    return false;
  }
  if (isProductConfigurable(product)) {
    return product.variants?.every((variant) => !!variant.product.order_number);
  }
  return true;
};

export const isProductProductWithdrawn = (product: Product): boolean => {
  if (!product || product.product_withdrawn !== 1) {
    return false;
  }
  if (isProductConfigurable(product)) {
    return product.variants?.every((variant) => variant.product.product_withdrawn === 1);
  }
  return true;
};

export const isProductEstimatedAvailability = (product: Product): boolean => {
  if (!product || !product.estimated_avalibility) {
    return false;
  }
  if (isProductConfigurable(product)) {
    return product.variants?.every((variant) => !!variant.product.estimated_avalibility);
  }
  return true;
};
