import fragmentMinPriceRangeFields from '~/modules/GraphQL/customQueries/fragments/fragmentMinPriceRangeFields';
import fragmentOptimizedProductFields from '~/modules/GraphQL/customQueries/fragments/fragmentOptimizedProductFields';

/** GraphQL query to get wishlist */
export default `
  #graphql
  query wishlist($currentPage: Int = 1, $pageSize: Int = 10) {
    customer {
      wishlists {
        id
        items_count
        sharing_code
        items_v2(currentPage: $currentPage, pageSize: $pageSize) {
          items {
            id
            quantity
            added_at
            product {
              ...OptimizedProductFields
              price_range {
                ...MinPriceRangeFields
              }
              url_key
              url_rewrites {
                url
              }
              ... on ConfigurableProduct {
                configurable_options {
                  attribute_code
                  attribute_uid
                  label
                  position
                  uid
                  use_default
                  values {
                    label
                    swatch_data {
                      value
                    }
                    uid
                  }
                }
                variants {
                  product {
                    price_range {
                      ...MinPriceRangeFields
                    }
                  }
                  attributes {
                    uid
                    label
                    code
                  }
                }
              }
              ... on BundleProduct {
                items {
                  sku
                  title
                  options {
                    uid
                    quantity
                    product {
                      uid
                      sku
                      name
                      price_range {
                        ...MinPriceRangeFields
                      }
                    }
                  }
                }
              }
            }
          }
          page_info {
            current_page
            page_size
            total_pages
          }
        }
      }
    }
  }
  ${fragmentMinPriceRangeFields}
  ${fragmentOptimizedProductFields}
`;
