import { defineStore } from 'pinia';

interface ProductState {
  productSKU: string | null;
  isModalOpen: boolean;
}

export const useProductModalStore = defineStore('productModal', {
  state: (): ProductState => ({
    productSKU: null,
    isModalOpen: false,
  }),
});
