import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import customCustomerOrders from '@/modules/GraphQL/customQueries/customerOrders.gql';
import type {
  UseUserOrderErrors,
  UseUserOrderInterface,
  UseUserOrderSearchParams,
} from './useUserOrder';

/**
 * Allows fetching customer orders.
 *
 * See the {@link UseUserOrderInterface} for a list of methods and values available in this composable.
 */
export function useUserOrder(): UseUserOrderInterface {
  const { app } = useContext();
  const loading = ref(false);
  const error = ref<UseUserOrderErrors>({
    search: null,
  });

  const search = async (params: UseUserOrderSearchParams) => {
    let results = null;

    try {
      loading.value = true;

      Logger.debug('[Magento] search user orders', { params });

      // const { data } = await app.$vsf.$magento.api.customerOrders(params, params?.customQuery ?? null, params?.customHeaders);
      const { data } = await app.context.$vsf.$magento.api.customQuery({
        query: customCustomerOrders, /* list of differences comparing to default:
        "company" and "vat_id" fields to "billing_address" and "shipping_address" objects,
        add new field "status_raw" we have "status" that returns label "status_raw" returns key
        replace orders with orders_ext, they are the same but different sorting  */
        queryVariables: params,
      });

      Logger.debug('[Result]:', { data });

      results = data?.customer?.orders_ext ?? null;
      error.value.search = null;
    } catch (err) {
      error.value.search = err;
      Logger.error('useRelatedProducts/search', err);
    } finally {
      loading.value = false;
    }

    return results;
  };

  return {
    search,
    loading: readonly(loading),
    error: readonly(error),
  };
}

export default useUserOrder;
export * from './useUserOrder';
