import { Logger } from '~/helpers/logger';
import { Cart } from '~/modules/GraphQL/types';
import { VsfContext } from '~/composables/context';
import removeCouponFromCart from '~/modules/GraphQL/customQueries/removeCouponFromCart.gql';

export const removeCouponCommand = {
  execute: async (context: VsfContext, { currentCart, customQuery = { removeCouponFromCart: 'removeCouponFromCart' }, customHeaders = {} }) => {
    Logger.debug('[Magento]: Remove coupon from cart', { currentCart });
    const input = {
      input: {
        cart_id: currentCart.id,
      },
    };
    const { data, errors } = await context.$magento.api.customQuery({
      query: removeCouponFromCart,
      queryVariables: input,
    });
    // @ts-ignore
    const responseData = data.removeCouponFromCart?.cart as unknown as Cart;

    Logger.debug('[Result]:', { data });

    return {
      updatedCart: responseData,
      updatedCoupon: { code: '' },
      errors,
    };
  },
};
