















import { defineComponent, ref } from '@nuxtjs/composition-api';
import type { Ref } from '@nuxtjs/composition-api';
import { useIntersectionObserver } from '~/composables';

export default defineComponent({
  name: 'LoadWhenVisible',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const seen: Ref = ref(null);
    const { isVisible } = useIntersectionObserver(seen);

    return {
      isVisible,
      seen,
    };
  },
});
