import {
  ref, readonly, computed, useContext,
} from '@nuxtjs/composition-api';
import type { UseOmnibusPriceInterface, SaleStrategy, UseOmnibusPriceErrors } from '~/modules/catalog/product/composables/useOmnibusPrice';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import type { Product } from '~/modules/catalog/product/types';
import { useOmnibusStore } from '~/modules/catalog/product/stores/omnibus';

/**
 * The `useOmnibusPrice()` composable allows you to fetch and display the omnibus price for a product.
 *
 * See the {@link UseOmnibusPriceInterface} page for more information.
 */
export function useOmnibusPrice(): UseOmnibusPriceInterface {
  const { env } = useContext();
  const omnibusStore = useOmnibusStore();
  const loading = ref(false);
  const error = ref<UseOmnibusPriceErrors>({
    fetch: null,
  });

  const filterSaleProducts = (products: Product[], strategy: SaleStrategy = 'cheapest'): Product[] => (products.reduce((saleProducts, product) => {
    if (productGetters.isProductConfigurable(product)) {
      if (strategy === 'cheapest') {
        const cheapestVariant = productGetters.getCheapestVariant(product);
        if (productGetters.getPrice(cheapestVariant).special) {
          saleProducts.push(cheapestVariant);
        }
      } else {
        product.variants?.forEach((variant) => {
          if (variant.product && productGetters.getPrice(variant.product).special) {
            saleProducts.push(variant.product);
          }
        });
      }
    } else if (productGetters.getPrice(product).special) {
      saleProducts.push(product);
    }
    return saleProducts;
  }, []));
  // TODO: move to helpers
  const mapProductsSku = (products: Product[]) => products.map((product) => product.sku);

  const mapPriceWithCurrency = (productSkuWithPrices: Record<string, any>, currency) => (
    Object.keys(productSkuWithPrices).reduce((acc, productSku) => {
      const productPrice = productSkuWithPrices[productSku] && productSkuWithPrices[productSku][currency] as string;
      if (productPrice) {
        acc[productSku] = Number.parseFloat(productPrice);
      }
      return acc;
    }, {})
  );

  const fetchOmnibusPriceList = async (productsSku: string[]) => {
    try {
      if (!productsSku || productsSku.length === 0) {
        return {};
      }
      return await fetch(
        `${env.VSF_OMNIBUS_API_URL}/api/category_prices?key=${env.VSF_OMNIBUS_API_KEY}`,
        {
          method: 'POST',
          body: JSON.stringify({
            ids: productsSku,
          }),
        },
      )
        .then((response) => response.json())
        .then((data: Record<string, any>) => mapPriceWithCurrency(data, 'PLN'));
    } catch {
      throw new Error('Problem with fetching prices from omnibus API');
    }
  };

  // create an array of skus of products that are on sale for the omnibus API
  const getSaleProductsSku = (products: Product[], strategy?: SaleStrategy): string[] => {
    const saleProducts = filterSaleProducts(products, strategy);
    const saleProductsSku = mapProductsSku(saleProducts);
    return saleProductsSku;
  };

  /*  const checkProductsSkuInStore = (productsSku:string[]):string[] => {
    // check if exists in store
    const omnibusPriceList = omnibusStore.omnibusPrices;
    if (omnibusPriceList) {
      const productsSkuWithPrices = Object.keys(omnibusPriceList);
      const missingProductsSku = productsSku.filter((sku) => !productsSkuWithPrices.includes(sku));
      if (missingProductsSku.length === 0) {
        return productsSku;
      }
      return missingProductsSku;
    }
    return [];
  }; */

  const getOmnibusPrices = async (products: Product[], strategy?: SaleStrategy) => {
    const saleProductsSku = getSaleProductsSku(products, strategy);
    // TODO add logic to check if exists in store fn checkProductsSkuInStore
    // const missingProductsSku = checkProductsSkuInStore(saleProductsSku);
    const omnibusPriceList = await fetchOmnibusPriceList(saleProductsSku);

    omnibusStore.$patch((state) => {
      state.omnibusPrices = { ...state.omnibusPrices, ...omnibusPriceList };
    });

    return omnibusStore.omnibusPrices;
  };

  return {
    getOmnibusPrices,
    error: readonly(error),
    loading: readonly(loading),
    omnibusPrices: computed(() => omnibusStore.omnibusPrices),
    getProductOmnibusPriceBySKU: (productSku: string): string | number | null => (
      omnibusStore.getProductPriceBySKU(productSku)
    ),
  };
}

export default useOmnibusPrice;
export * from './useOmnibusPrice';
