import {
  onMounted, onUnmounted, ref, useContext,
} from '@nuxtjs/composition-api';

export function useRecaptcha() {
  // @ts-expect-error Recaptcha is not registered as a Nuxt module. Its absence is handled in the code
  const { $recaptcha, $config } = useContext();
  const isRecaptchaEnabled = ref<boolean>(typeof $recaptcha !== 'undefined' && Boolean($config.isRecaptcha));

  onMounted(async () => {
    if (!isRecaptchaEnabled.value) return;
    await $recaptcha.init();
  });

  onUnmounted(() => {
    if (!isRecaptchaEnabled.value) return;
    $recaptcha.destroy();
  });

  const getRecaptchaToken = async (actionName: string): Promise<string> | null => {
    if (isRecaptchaEnabled.value) return await $recaptcha.execute(actionName);
    return null;
  };

  return { getRecaptchaToken };
}
