export default `
  #graphql
  fragment MinPriceRangeFields on PriceRange {
    minimum_price {
      final_price {
        currency
        value
      }
      regular_price {
        currency
        value
      }
    }
  }
`;
