import { defineStore } from 'pinia';
import { CategoryTree, UseCategoryParamsInput } from '~/composables';
import categoryListGql from '~/modules/GraphQL/customQueries/categoryListWithChildrens.gql';
import categoryListLandingGql from '~/modules/GraphQL/customQueries/categoryListWithChildrensLanding.gql';
import categoryListWithOneChildrenGql from '~/modules/GraphQL/customQueries/categoryListWithOneChildren.gql';
import { Logger } from '~/helpers/logger';

interface CategoryState {
  rawCategories: CategoryTree[] | null;
  isRootLoading: boolean;
}

export const useCategoryStore = defineStore('category', {
  state: (): CategoryState => ({
    rawCategories: null,
    isRootLoading: false,
  }),
  actions: {
    async load(params: UseCategoryParamsInput, parentUid, isLanding = false) {
      Logger.debug('useCategoryStore/load', params);
      const { filters, pageSize } = params;
      const { data }: { data: { categoryList?: CategoryTree[] } } = await this.$nuxt.app.$vsf.$magento.api.customQuery({
        query: isLanding ? categoryListLandingGql : categoryListGql,
        queryVariables: {
          filters,
          pageSize,
        },
      });
      if (!this.rawCategories || this.rawCategories.length === 0) {
        return;
      }
      const rawCategoriesChildrensExtended = this.rawCategories[0]?.children.map((category) => {
        const categoryCopy = { ...category };
        if (category.uid === parentUid) {
          categoryCopy.children = data?.categoryList;
        }
        return categoryCopy;
      });
      const rawCategoriesCopy = [...this.rawCategories];
      this.rawCategories = null;
      this.rawCategories = [{ ...rawCategoriesCopy[0], children: rawCategoriesChildrensExtended }];
    },
    async loadRoot(params: UseCategoryParamsInput) {
      this.isRootLoading = true;
      Logger.debug('useCategoryStore/load', params);
      const { filters, pageSize } = params;
      const { data }: { data: { categoryList?: CategoryTree[] } } = await this.$nuxt.app.$vsf.$magento.api.customQuery({
        query: categoryListWithOneChildrenGql,
        queryVariables: {
          filters,
          pageSize,
        },
      });
      this.rawCategories = data?.categoryList ?? null;
      this.isRootLoading = false;
    },
  },
  getters: {
    categories(state) {
      if (state.rawCategories === null) {
        return null;
      }

      return state.rawCategories;
    },
    isLoading(state) {
      return state.isRootLoading;
    },
  },
});
