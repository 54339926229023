export default `
  #graphql
  query amSocialLoginButtonConfig {
    amSocialLoginButtonConfig {
      type
      label
      url
    }
  }
`;
