import { Product } from '~/modules/catalog/product/types';
import {
  getName,
  getPrice,
} from '~/modules/catalog/product/getters/productGetters';
import type {
  Aggregation, ProductInterface, SimpleProduct, Maybe, CategoryInterface,
} from '~/modules/GraphQL/types';
import { ConfigurableCartItem, CartItemInterface } from '~/composables';

export interface DLProductParams {
  product: Product | ProductInterface | SimpleProduct,
  variant?: Product | ProductInterface | SimpleProduct,
  optional?: {
    attributes?: Aggregation[],
    quantity?: number,
    list?: string,
    position?: number
  }
}

export interface DLProduct {
  parentSku: string,
  parentId: number,
  name: string,
  sku: string,
  id: number,
  final_price_tax: number,
  product_category_ids?: string[],
  product_category_names?: string[],
  brand: string,
  final_price?: number,
  list?: string,
  position?: number,
  qty?: number,
  full_category_path?: string,
  is_in_stock?: boolean
}

export function getFullCategoryPath(mainCategory: number, categories: Maybe<Array<Maybe<CategoryInterface>>>): string {
  if (!mainCategory || !categories || !categories?.length) return '';

  const categoriesMap = new Map();
  let pathIds: string[] = [];
  categories.forEach((category) => {
    if (category?.id === mainCategory) {
      pathIds = category?.path?.split('/')?.splice(2);
    }

    if (category?.id) categoriesMap.set(category?.id, category?.name);
  });

  return pathIds.map((id) => categoriesMap.get(Number(id))).join('/');
}

export function productToDLProduct({
  product,
  variant,
  optional: {
    quantity,
    list,
    position,
  },
}: DLProductParams): DLProduct {
  const currentProduct = variant || product;

  const title = getName(product);

  const getManufacturerLabel = () => {
    if (!currentProduct?.manufacturer) return '';

    for (let i = 0; i < currentProduct?.custom_attributes?.length; i++) {
      const attribute = currentProduct?.custom_attributes[i];
      if (attribute?.attribute_metadata?.code === 'manufacturer') {
        return attribute?.selected_attribute_options?.attribute_option[0]?.label;
      }
    }

    return '';
  };
  const finalPrice = getPrice(currentProduct).final;
  // const manufacturerLabel = currentProduct.manufacturer && attributes ? getAttributeLabel('manufacturer', currentProduct.manufacturer, attributes) : '';
  const manufacturerLabel = getManufacturerLabel();
  const categoryIds = product.categories?.map((category) => category?.id?.toString());
  const categoryNames = product.categories?.map((category) => category?.name);
  const DLProduct: DLProduct = {
    parentSku: product.sku,
    parentId: product.id,
    name: title,
    sku: currentProduct.sku,
    id: currentProduct.id,
    final_price_tax: 0,
    brand: manufacturerLabel,
    is_in_stock: currentProduct.stock_status === 'IN_STOCK',
  };
  if (categoryIds) {
    DLProduct.product_category_ids = categoryIds;
  }
  if (categoryNames) {
    DLProduct.product_category_names = categoryNames;
  }
  if (finalPrice) {
    DLProduct.final_price = finalPrice;
  }
  if (list) {
    DLProduct.list = list;
  }
  if (position) {
    DLProduct.position = position;
  }
  if (quantity) {
    DLProduct.qty = quantity;
  }
  if (product?.main_category) {
    const fullPath = getFullCategoryPath(product.main_category, product.categories);

    if (fullPath) {
      DLProduct.full_category_path = fullPath;
    }
  }
  return DLProduct;
}

export function cartItemsToDLProducts(products: (ConfigurableCartItem | CartItemInterface)[], _attributes?: Aggregation[]) {
  const DLproducts = products.map((product) => {
    let variant;
    if ('configured_variant' in product) {
      variant = product.configured_variant;
    }
    return productToDLProduct({
      product: product.product,
      variant,
      optional: {
        quantity: product.quantity,
      },
    });
  });
  return DLproducts;
}

export const addPrefixToKeys = (obj: Record<string, any>, prefix: string): Record<string, any> => Object.keys(obj).reduce((acc, key) => {
  acc[`${prefix}${key}`] = obj[key];
  return acc;
}, {} as Record<string, any>);
