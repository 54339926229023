




























































































import LazyHydrate from 'vue-lazy-hydration';
import { useRoute, defineComponent } from '@nuxtjs/composition-api';
import { addBasePath } from '~/helpers/addBasePath';
import { SfImage } from '@storefront-ui/vue';

export default defineComponent({
  name: 'CheckoutLayout',
  components: {
    SfImage,
    LazyHydrate,
    Notification: () => import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
    HeaderSteps: () => import(/* webpackPrefetch: true */ '~/modules/checkout/components/HeaderSteps.vue'),
  },
  setup() {
    const route = useRoute();

    return {
      route,
      addBasePath,
    };
  },
});
