import { defineStore } from 'pinia';

interface OmnibusState {
  omnibusPrices: Record<string, string | number> | null,
}

export const useOmnibusStore = defineStore('omnibus', {
  state: (): OmnibusState => ({
    omnibusPrices: null,
  }),
  actions: {
    setOmnibusPrices(omnibusPrices: Record<string, string | number>) {
      this.omnibusPrices = { ...this.omnibusPrices, ...omnibusPrices };
    },
    getProductPriceBySKU(productSku: string): string | number | null {
      return this.omnibusPrices ? this.omnibusPrices[productSku] : null;
    },
  },
});
