export const productAdditionalFields = [
  'cisnienie',
  'dla_leworecznych',
  'dysza_dolewajaca',
  'dysza_glowna',
  'length',
  'dlugosc_ostrza',
  'srednica_cal',
  'depth',
  'hodowla',
  'ilosc_nasion',
  'ilosc_warstw',
  'lanca',
  'color',
  'moc_silnika',
  'obrotowa_rekojesc',
  'pack',
  'volume',
  'przylacze',
  'przylacze_dolne',
  'przylacze_gorne',
  'rodzaj_sekatora',
  // TODO: uncomment after changes in magento
  // 'rodzaj_strumienia',
  // 'rozmiar',
  'rodzaj_zebow_pily',
  'size',
  'spacing',
  'zaprawa',
  'diameter',
  'srednica_ciecia',
  'width',
  'temperatura_robocza',
  'typ_glowicy_sekatora',
  'product_weight',
  'wydajnosc',
  'zasilanie',
  'screw',
  'wysokosc',
];
export default `
  #graphql
  fragment ProductAdditionalFields on ProductInterface {
    ${productAdditionalFields.join('\n')}
  }
`;
