export default `
query getAmDefaultSorting($pageType: PageTypeEnum!) {
  defaultSorting(pageType: $pageType) {
    attribute
    id
    text
    sortDirection
  }
}
`;
