import type { Plugin } from '@nuxt/types';
import { GA as GA4 } from './ga4';

declare module 'vue/types/vue' {
  interface Vue {
    $googleTagManager: GA4;
  }
}

const plugin : Plugin = (_, inject) => {
  const ga4 = new GA4();
  inject('googleTagManager', ga4);
};

export default plugin;
