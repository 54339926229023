





































































































import {
  defineComponent, ref, useRoute, useRouter,
} from '@nuxtjs/composition-api';
import { SfImage } from '@storefront-ui/vue';
import LazyHydrate from 'vue-lazy-hydration';
import { addBasePath } from '~/helpers/addBasePath';

export default defineComponent({
  name: 'AuthLayout',
  components: {
    SfImage,
    LazyHydrate,
    Notification: () => import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const isReturnURL = ref(!!route.value.query?.returnURL);

    return {
      route,
      isReturnURL,
      router,
      addBasePath,
    };
  },
});
