import { ref } from '@nuxtjs/composition-api';

export interface UseCounterOptions {
  min?: number
  max?: number
}

/**
 * Basic counter with utility functions.
 *
 * @param [initialValue]
 * @param options
 */
export function useCounter(initialValue: number, options: UseCounterOptions = {}) {
  // eslint-disable-next-line no-underscore-dangle
  let initialValueBackup = initialValue;
  const count = ref(initialValue);

  const {
    max = Number.POSITIVE_INFINITY,
    min = Number.NEGATIVE_INFINITY,
  } = options;

  const inc = (delta = 1) => { count.value = Math.max(Math.min(max, count.value + delta), min); };
  const dec = (delta = 1) => { count.value = Math.min(Math.max(min, count.value - delta), max); };
  const get = () => count.value;
  const set = (val: number) => { (count.value = Math.min(max, val)); };
  const reset = (val = initialValueBackup) => {
    initialValueBackup = val;
    return set(val);
  };

  return {
    count, inc, dec, get, set, reset,
  };
}
