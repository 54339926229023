import { Logger } from '~/helpers/logger';
import { Cart, RemoveItemFromCartInput, RemoveItemFromCartMutation } from '~/modules/GraphQL/types';
import { VsfContext } from '~/composables/context';
import customRemoveItemFromCartGql from '@/modules/GraphQL/customQueries/removeItemFromCart.gql';

export const removeItemCommand = {
  execute: async (
    context: VsfContext,
    {
      currentCart,
      product,
      customQuery = { removeItemFromCart: 'removeItemFromCart' },
      customHeaders,
    },
  ) => {
    Logger.debug('[Magento]: Remove item from cart', {
      product,
      currentCart,
    });

    const item = currentCart.items.find((cartItem) => cartItem.uid === product.uid);

    if (!item) {
      return;
    }

    const removeItemParams: RemoveItemFromCartInput = {
      cart_id: currentCart.id,
      cart_item_uid: item.uid,
    };

    // vsf code
    // const { data } = await context.$magento.api.removeItemFromCart(
    //   removeItemParams,
    //   customQuery as CustomQuery,
    //   customHeaders as CustomHeaders,
    // );

    const result = await context.$magento.api.customQuery({
      query: customRemoveItemFromCartGql,
      queryVariables: {
        input: removeItemParams,
      },
      customHeaders,
    });
    const data = result.data as RemoveItemFromCartMutation;
    Logger.debug('[Result]:', { data });

    // eslint-disable-next-line consistent-return
    return data
      .removeItemFromCart
      .cart as unknown as Cart;
  },
};
