


















import {
  defineComponent, ref, PropType, computed,
} from '@nuxtjs/composition-api';
import type { Ref } from '@nuxtjs/composition-api';
import { useIntersectionObserver } from '~/composables';

export interface Skeleton {
  pcHeight?: string;
  tabletHeight?: string;
  mobileHeight?: string;
}

export default defineComponent({
  name: 'LoadWhenVisible',
  props: {
    options: {
      type: Object as PropType<IntersectionObserverInit>,
      default: () => ({
        rootMargin: '0px 0px 200px 0px',
      }),
    },
    id: {
      type: String as PropType<string>,
      default: '',
    },
    skeleton: {
      type: Object as PropType<Skeleton>,
      default: () => ({
        pcHeight: '31.25rem',
        tabletHeight: '18.75rem',
        mobileHeight: '12.5rem',
      }),
    },
  },
  setup(props) {
    const root: Ref = ref(null);
    const { isVisible } = useIntersectionObserver(root, props.options);
    const randomId = (): string => Math.random().toString(36).slice(2, 11);
    const currentId = props.id || randomId();

    const skeletonHeightCSS = computed(() => `
    .skeleton-height--${currentId} {
      height: ${props.skeleton.mobileHeight};
      min-height: ${props.skeleton.mobileHeight};
    }

    @media (min-width: 768px) {
      .skeleton-height--${currentId} {
        height: ${props.skeleton.tabletHeight};
        min-height: ${props.skeleton.tabletHeight};
      }
    }
    @media (min-width: 1024px) {
      .skeleton-height--${currentId} {
        height: ${props.skeleton.pcHeight};
        min-height: ${props.skeleton.pcHeight};
      }
    }
    `);

    return {
      root,
      isVisible,
      currentId,
      skeletonHeightCSS,
    };
  },
});
