/* eslint-disable import/no-mutable-exports */
import {
  Product, Optional, CategoryResolver, User,
} from './interfaces';
import config from './config';

export let categoryResolver: CategoryResolver;
export let currency: string;
let language: string;

export function setCategoryResolver(resolver: CategoryResolver) {
  categoryResolver = resolver;
}

export function indexCategories(menuCategories: any[]) {
  if (!categoryResolver) return;
  categoryResolver.setCategories(menuCategories);
}

export function setCurrency(newCurrency: string) {
  currency = newCurrency;
}

export function getCurrency(): string {
  if (typeof currency !== 'undefined') {
    return currency;
  }
  return 'PLN';
}

export function setLanguage(newLanguage: string) {
  language = newLanguage;
}

export function getLanguage(): string {
  if (typeof language !== 'undefined') {
    return language;
  }
  return 'pl';
}

export function getDL(): any[] {
  (window as any).dataLayer = (window as any).dataLayer || [];
  return (window as any).dataLayer;
}

export function getdeepestCategoryIds(product: Product): string[] | undefined {
  if (typeof product.category === 'undefined' || !categoryResolver) {
    return undefined;
  }
  if (!product.category) {
    return [];
  }
  try {
    return categoryResolver.getDeepestCategories(product.category, false);
  } catch {
    return undefined;
  }
}

export function getCategoryNames(categoryIds: string[], startingFrom?: number): string[] | undefined {
  if (!categoryResolver) {
    return undefined;
  }
  try {
    return categoryIds.map((x) => categoryResolver && categoryResolver.getNamedPath(x, startingFrom));
  } catch {
    return undefined;
  }
}

export function getProductCategories(product: Product, includeRaw: boolean = false): {
  category: string,
  product_category_ids: string[] | undefined,
  product_category_names: string[] | undefined
} {
  let categoryNames: string[] = [];
  let category = '';
  let categoryIds: string[] = [];
  // add raw data when categoryResolver is not set
  category = product.full_category_path;
  categoryIds = product.product_category_ids;
  categoryNames = product.product_category_names;
  return {
    category,
    product_category_ids: includeRaw ? categoryIds : undefined,
    product_category_names: includeRaw ? categoryNames : undefined,
  };
}

export function getGaConfig(param: string, defaultValue?: any, restrict?: any[]): any {
  let val = defaultValue;
  const noConfig: boolean = typeof config === 'undefined' || typeof config.googleTagManager === 'undefined';
  if (noConfig || typeof config.googleTagManager[param] === 'undefined') {
    return val;
  }
  val = config.googleTagManager[param];

  if (typeof restrict !== 'undefined' && !restrict.includes(val)) {
    console.error(`wrong config ${param} param: ${val}, set as ${defaultValue}`);
    return defaultValue;
  }
  return val;
}

export function getPrice(product: Product): string {
  // TODO: replace dynamic fields with static ones
  const param = (getGaConfig('priceParam', 'final', ['original', 'final']) as string);
  const taxInPrice = (getGaConfig('taxInPrice', true) as boolean);
  const paramName = `${param}_price${taxInPrice ? '_incl_tax' : ''}`;
  return typeof product[paramName] === 'undefined' ? product.final_price : product[paramName];
}
export function getTax(product: Product): string | undefined {
  const trackTax = (getGaConfig('trackTax', false) as boolean);
  const param = (getGaConfig('priceParam', 'final', ['original', 'final']) as string);
  const paramName = `${param}_price_tax`;
  if (!trackTax) return undefined;
  return product[paramName];
}
export function formatPrice(price?: string): string | undefined {
  if (typeof price === 'undefined') {
    return undefined;
  }
  return Math.abs(Number.parseFloat(price)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export function getProductPrices(product: Product): { tax: string | undefined, price: string } {
  return {
    tax: getTax(product),
    price: getPrice(product),
  };
}

export function getProductIds(product: Product): { id: string, dbId?: string, parentDbId?: string, childSku?: string, parentSku?: string } {
  const param = (getGaConfig('idParam', 'sku', ['sku', 'parentSku', 'id', 'parentId']) as string);
  return {
    id: product[param],
    dbId: product.id ? product.id.toString() : undefined,
    parentDbId: product.parentId ? product.parentId.toString() : undefined,
    childSku: product.sku,
    parentSku: product.parentSku,
  };
}

export function patchObject(arg: object, optional?: Optional): object {
  if (optional) {
    Object.assign(arg, optional);
  }
  return arg;
}

export function adapterUserAddress(user?: User): { country?: string, city?: string } {
  if (!(user && user.addresses && user.addresses.length > 0)) return {};
  const address = user.addresses[0];
  return {
    country: address.country_id,
    city: address.city,
  };
}
