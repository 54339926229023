/** GraphQL Query that fetches the category list. */
const fragmentCategory = `
  fragment CategoryFields on CategoryTree {
    include_in_menu
    is_anchor
    level
    name
    position
    product_count
    children_count
    uid
    url_path
    url_suffix
    thumbnail_image_2
    megamenu_image_1
    megamenu_image_2
    megamenu_image_1_link
    megamenu_image_2_link
  }
`;

export default `
  query categoryList($filters: CategoryFilterInput) {
    categoryList(filters: $filters) {
      ...CategoryFields
      children {
        ...CategoryFields
        children {
          ...CategoryFields
          children {
            ...CategoryFields
          }
        }
      }
    }
  }
  ${fragmentCategory}
`;
