export default function scrollBehavior(to, _from, savedPosition) {
  if (to.hash) {
    return {
      el: to.hash,
      behavior: 'smooth',
    };
  }
  if (savedPosition) {
    return new Promise((resolve, _reject) => {
      setTimeout(() => {
        resolve(savedPosition);
      }, 500);
    });
  }
  return window.scrollTo(0, 0); // { left: 0, top: 0 };
}
