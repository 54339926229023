import {
  computed, useContext, useRoute,
} from '@nuxtjs/composition-api';
import { useCategoryStore } from '~/modules/catalog/category/stores/category';
import { findActiveCategory } from '~/modules/catalog/category/helpers/findActiveCategory';
import { findCategoryAncestors } from '~/modules/catalog/category/helpers/findCategoryAncestors';

/**
 * Logic for finding the current product category and its parent and grandparent categories (ancestors)
 */
export function useTraverseCategory() {
  const context = useContext();
  const categoryStore = useCategoryStore();
  const route = useRoute();

  const getChildCategoryQueryParams = (uid) => ({
    filters: {
      parent_category_uid: {
        eq: uid,
      },
    },
    pageSize: 9999,
  });

  const categoryTree = computed(() => categoryStore.categories);
  const isCategoryTreeLoaded = computed(() => categoryStore.isLoading || categoryStore.categories !== null);
  const loadCategoryTree = (uid) => uid && categoryStore.load(getChildCategoryQueryParams(uid), uid);
  const loadCategoryTreeLanding = (uid) => uid && categoryStore.load(getChildCategoryQueryParams(uid), uid, true);
  const loadRootCategoryTree = () => categoryStore.loadRoot({
    filters: {
      category_uid: {
        eq: context.env.VSF_MAGENTO_ROOT_CATEGORY_UID,
      },
    },
    pageSize: 9999,
  });
  const initCategoryTree = async () => {
    await loadRootCategoryTree();
    await loadCategoryTree(context.env.VSF_MAGENTO_MAIN_CATEGORY_UID);
  };
  const activeCategory = computed(() => {
    // on localhost the default store is localhost:3000/default/ but in a multi-store Magento instance this can change
    const urlPathToFind = route.value.path
      .replace(context.app.localePath('/'), '')
      .replace(/^\//, '')
      .replace('.html', '');

    return categoryTree.value === null ? null : findActiveCategory(categoryTree.value, urlPathToFind);
  });
  const activeMainCategory = computed(() => {
    const urlPathToFind = route.value.path
      .replace(context.app.localePath('/'), '')
      .replace(/^\//, '')
      .replace('.html', '')
      .split('/')[0];

    return categoryTree.value === null ? null : findActiveCategory(categoryTree.value, urlPathToFind);
  });

  const categoryAncestors = computed(() => (activeCategory.value === null
    ? []
    : findCategoryAncestors(categoryTree.value[0], activeCategory.value)) ?? []);

  return {
    activeCategory,
    activeMainCategory,
    categoryAncestors,
    categoryTree,
    loadCategoryTree,
    loadRootCategoryTree,
    loadCategoryTreeLanding,
    initCategoryTree,
    isCategoryTreeLoaded,
  };
}
