import { Logger } from '~/helpers/logger';
import { Cart, UpdateCartItemsInput, UpdateCartItemsMutation } from '~/modules/GraphQL/types';
import { VsfContext } from '~/composables/context';
import customUpdateCartItemsGql from '@/modules/GraphQL/customQueries/updateCartItems.gql';

export const updateItemQtyCommand = {
  execute: async (context: VsfContext, {
    currentCart,
    product,
    quantity,
    customQuery = { updateCartItems: 'updateCartItems' },
    customHeaders = {},
  }) => {
    Logger.debug('[Magento]: Update product quantity on cart', {
      product,
      quantity,
      currentCart,
    });

    const updateCartParams: UpdateCartItemsInput = {
      cart_id: currentCart.id,
      cart_items: [
        {
          cart_item_uid: product.uid,
          quantity,
        },
      ],
    };

    // const { data } = await context.$magento.api.updateCartItems(updateCartParams, customQuery, customHeaders);
    const result = await context.$magento.api.customQuery({
      query: customUpdateCartItemsGql,
      queryVariables: {
        input: updateCartParams,
      },
      customHeaders,
    });
    const data = result.data as UpdateCartItemsMutation;
    Logger.debug('[Result]:', { data });

    // @ts-ignore
    if (result?.errors?.length > 0) {
      // @ts-ignore
      throw new Error(String(result.errors[0].message));
    }

    return data
      .updateCartItems
      .cart as unknown as Cart;
  },
};
