import { ref, onMounted, onUnmounted } from '@nuxtjs/composition-api';
import type { Ref } from '@nuxtjs/composition-api';

export function useIntersectionObserver(root: Ref, options: IntersectionObserverInit = { rootMargin: '0px 0px 200px 0px' }) {
  const isVisible = ref(false);

  const observer = ref(null);

  onMounted(() => {
    if (process.browser && ('IntersectionObserver' in window)) {
      observer.value = new IntersectionObserver((entries) => {
        if (entries[0].intersectionRatio <= 0) return;
        observer.value.unobserve(root.value);
        isVisible.value = true;
      }, options);
    }

    if (observer.value) {
      observer.value.observe(root.value);
    }
  });

  onUnmounted(() => {
    if (observer.value) {
      observer.value.unobserve(root.value);
    }
  });

  return {
    isVisible,
  };
}
