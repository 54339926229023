

























import LazyHydrate from 'vue-lazy-hydration';
import { useRoute, defineComponent, useFetch } from '@nuxtjs/composition-api';
import { useUiState } from '~/composables';
import { useProductModalStore } from '~/stores/productModal';
import { storeToRefs } from 'pinia';
import BottomNavigation from '~/components/BottomNavigation.vue';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import { useTraverseCategory } from '~/modules/catalog/category/helpers/useTraverseCategory';

export default defineComponent({
  name: 'BasicLayout',
  components: {
    LoadWhenVisible,
    LazyHydrate,
    BottomNavigation,
    ProductOmnibusPopup: () => import(/* webpackPrefetch: true */ '~/modules/catalog/product/components/ProductOmnibusPopup.vue'),
    ProductModal: () => import(/* webpackPrefetch: true */ '~/components/ProductModal.vue'),
    MainHeader: () => import(/* webpackPrefetch: true */ '~/components/MainHeader/MainHeader.vue'),
    MainFooter: () => import(/* webpackPrefetch: true */ '~/components/MainFooter/MainFooter.vue'),
    LoginModal: () => import(/* webpackPrefetch: true */ '~/modules/customer/components/LoginModal/LoginModal.vue'),
    WishlistSidebar: () => import(/* webpackPrefetch: true */ '~/modules/wishlist/components/WishlistSidebar.vue'),
    ScrollToTop: () => import(/* webpackPrefetch: true */ '~/components/ScrollToTop.vue'),
    CartSidebar: () => import(/* webpackPrefetch: true */ '~/modules/checkout/components/CartSidebar.vue'),
    Notification: () => import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
  },

  setup() {
    const route = useRoute();
    const {
      isLoginModalOpen, isWishlistSidebarOpen, isCartSidebarOpen, toggleLoginModal, isProductStickyVariantOpen,
    } = useUiState();
    const productModalStore = useProductModalStore();
    const { isModalOpen } = storeToRefs(productModalStore);
    const { initCategoryTree, isCategoryTreeLoaded } = useTraverseCategory();

    useFetch(async () => {
      if (!isCategoryTreeLoaded.value) {
        await initCategoryTree();
      }
    });

    return {
      isWishlistSidebarOpen,
      isLoginModalOpen,
      isCartSidebarOpen,
      isProductStickyVariantOpen,
      toggleLoginModal,
      route,
      isModalOpen,
    };
  },
});
