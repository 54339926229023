import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { getProductListCommand } from '~/modules/catalog/product/composables/useProduct/commands/getProductListCommand';
import { getProductListPreviewCommand } from '~/modules/catalog/product/composables/useProduct/commands/getProductListPreviewCommand';
import { getProductListMiniCommand } from '~/modules/catalog/product/composables/useProduct/commands/getProductListMiniCommand';
import { getProductDetailsCommand } from '~/modules/catalog/product/composables/useProduct/commands/getProductDetailsCommand';
import type { GetProductSearchParams } from '~/modules/catalog/product/types';
import type { ProductInterface } from '~/modules/GraphQL/types';
import { Aggregation } from '@vue-storefront/magento-types';
import { getProductsAggregationsCommand } from '~/modules/catalog/product/composables/useProduct/commands/getProductsAggregationsCommand';

import type {
  ProductDetails,
  ProductList,
  UseProductErrors,
  UseProductInterface,
} from './useProduct';

/**
 * Allows loading product details or list with
 * params for sorting, filtering and pagination.
 *
 * See the {@link UseProductInterface} for a list of methods and values available in this composable.
 */
export function useProduct(id?: string): UseProductInterface {
  const loading = ref(false);
  const error = ref<UseProductErrors>({
    getProductList: null,
    getProductListPreview: null,
    getProductListMini: null,
    getProductDetails: null,
    getProductAttributes: null,
  });

  const context = useContext();

  const getProductList = async (searchParams: GetProductSearchParams): Promise<ProductList | null> => {
    Logger.debug(`useProduct/${id}/getProductList`, searchParams);
    let products: ProductList = null;

    try {
      loading.value = true;
      products = await getProductListCommand.execute(context, searchParams);
      error.value.getProductList = null;
    } catch (err) {
      error.value.getProductList = err;
      Logger.error(`useProduct/${id}/search`, err);
    } finally {
      loading.value = false;
    }

    return products;
  };

  const getProductListPreview = async (searchParams: GetProductSearchParams): Promise<ProductList | null> => {
    Logger.debug(`useProduct/${id}/getProductListPreview`, searchParams);
    let products: ProductList = null;

    try {
      loading.value = true;
      products = await getProductListPreviewCommand.execute(context, searchParams);
      error.value.getProductListPreview = null;
    } catch (err) {
      error.value.getProductListPreview = err;
      Logger.error(`useProduct/${id}/search`, err);
    } finally {
      loading.value = false;
    }

    return products;
  };

  const getProductListMini = async (searchParams: GetProductSearchParams): Promise<ProductList | null> => {
    Logger.debug(`useProduct/${id}/getProductListMini`, searchParams);
    let products: ProductList = null;

    try {
      loading.value = true;
      products = await getProductListMiniCommand.execute(context, searchParams);
      error.value.getProductListMini = null;
    } catch (err) {
      error.value.getProductListMini = err;
      Logger.error(`useProduct/${id}/search`, err);
    } finally {
      loading.value = false;
    }

    return products;
  };

  const getProductDetails = async (searchParams: GetProductSearchParams): Promise<ProductDetails | null> => {
    Logger.debug(`useProduct/${id}/getProductDetails`, searchParams);
    let products: ProductDetails = null;

    try {
      loading.value = true;
      products = await getProductDetailsCommand.execute(context, searchParams);
      error.value.getProductDetails = null;
    } catch (err) {
      error.value.getProductDetails = err;
      Logger.error(`useProduct/${id}/search`, err);
    } finally {
      loading.value = false;
    }

    return products;
  };

  const getProductPath = (product: ProductInterface) => {
    if (!product) return '/';
    return `/${product.url_key}`;
  };

  const getProductAttributes = async (productSKU:string): Promise<Aggregation[] | null> => {
    let attrs: Aggregation[] | null = null;
    try {
      loading.value = true;
      const searchParams = { filter: { sku: { eq: productSKU } } };
      attrs = await getProductsAggregationsCommand.execute(context, searchParams);
      error.value.getProductAttributes = null;
    } catch (err) {
      error.value.getProductAttributes = err;
    } finally {
      loading.value = false;
    }
    return attrs;
  };

  return {
    getProductListMini,
    getProductListPreview,
    getProductList,
    getProductDetails,
    getProductPath,
    getProductAttributes,
    error: readonly(error),
    loading: readonly(loading),
  };
}

export * from './useProduct';
export default useProduct;
