export default `
  #graphql
  fragment CartItemFields on ProductInterface {
    __typename
    id
    uid
    sku
    stock_status
    name
    manufacturer
    bestseller
    special_price
    main_category
    invoice_only

    categories {
      id
      name
      level
      path
    }
    stock_item {
      qty_increments
      min_sale_qty
      max_sale_qty
    }
    thumbnail {
      url
    }
    custom_attributes {
      attribute_metadata {
        code
        uid
      }
      entered_attribute_value {
        value
      }
      selected_attribute_options {
        attribute_option {
          is_default
          label
          uid
        }
      }
    }
  }
`;
