import { UseContextReturn } from '~/types/core';
import type { GetProductSearchParams } from '~/modules/catalog/product/types';
import getProductsAggregations from '~/modules/catalog/product/composables/useProduct/getProductsAggregations.gql';

export const getProductsAggregationsCommand = {
  execute: async (context: UseContextReturn, searchParams: GetProductSearchParams) => {
    const { data } = await context.app.$vsf.$magento.api.customQuery({
      query: getProductsAggregations,
      queryVariables: searchParams,
    });
    // @ts-ignore
    return data?.products?.aggregations ?? [];
  },
};
