import fragmentPriceRangeFields from '~/modules/GraphQL/customQueries/fragments/fragmentPriceRangeFields';
import fragmentCartItem from '~/modules/GraphQL/customQueries/fragments/fragmentCartItem';
import fragmentSelectedShippingMethodFields from '~/modules/GraphQL/customQueries/fragments/fragmentSelectedShippingMethodFields';
import fragmentPrices from '~/modules/GraphQL/customQueries/fragments/fragmentPrices';

/** GraphQL query to fetch a cart */
export default `
  #graphql
  query cart($cartId: String!) {
    cart(cart_id:$cartId) {
      id
      email
      is_virtual
      applied_coupons {
        code
      }
      prices {
        ...CartPrices
      }
      items {
        uid
        product {
          ...CartItemFields
          price_range {
            ...PriceRangeFields
          }
          url_key
          categories {
            uid
            name
            url_suffix
            url_path
            url_key
          }
        }
        prices {
          row_total {
            value
            currency
          }
          row_total_including_tax {
            value
            currency
          }
          total_item_discount {
            value
            currency
          }
        }
        quantity
        ... on ConfigurableCartItem {
          configurable_options {
            configurable_product_option_uid
            option_label
            configurable_product_option_value_uid
            value_label
          }
          configured_variant {
            ...CartItemFields
            price_range {
              ...PriceRangeFields
            }
          }
        }
        ... on BundleCartItem {
          bundle_options {
            uid
            label
            type
            values {
              id
              label
              price
              quantity
            }
          }
        }
      }
      total_quantity
      consents {
        usercom_id
        consent_to_rules
        consent_to_phone_commercials
        consent_to_email_commercials
      }
      selected_payment_method {
        code
        purchase_order_number
        title
        payu_additional_data {
          payu_method_type
          payu_method
          base_return_url
        }
      }
      shipping_addresses {
        customer_notes
        firstname
        lastname
        street
        city
        company
        region {
          code
          region_id
          label
        }
        postcode
        telephone
        country {
          code
          label
        }
        selected_shipping_method {
          ...SelectedShippingMethodFields
        }
      }
      billing_address {
        firstname
        lastname
        street
        city
        company
        region {
          code
          region_id
          label
        }
        postcode
        telephone
        country {
          code
          label
        }
      }
    }
  }
  ${fragmentPriceRangeFields}
  ${fragmentCartItem}
  ${fragmentSelectedShippingMethodFields}
  ${fragmentPrices}
`;
