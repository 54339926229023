import fragmentPriceRangeFields from '~/modules/GraphQL/customQueries/fragments/fragmentPriceRangeFields';
import fragmentOptimizedProductFields from '~/modules/GraphQL/customQueries/fragments/fragmentOptimizedProductFields';
import fragmentOptimizedVariantFields from '~/modules/GraphQL/customQueries/fragments/fragmentOptimizedVariantFields';

/**
 * GraphQL Query that fetches products for product tile (sliders, previews) using received search term and the params
 * for filter, sort and pagination.
 */
export default `
  #graphql
  query productsList(
    $search: String = "",
    $filter: ProductAttributeFilterInput,
    $pageSize: Int = 10,
    $currentPage: Int = 1,
    $sort: ProductAttributeSortInput
  ) {
    products(search: $search, filter: $filter, pageSize: $pageSize, currentPage: $currentPage, sort: $sort) {
      aggregations {
        attribute_code
        label
        options {
          label
          value
          count
        }
      }
      items {
        ...OptimizedProductFields
        price_range {
          ...PriceRangeFields
        }
        url_key

        ... on ConfigurableProduct {
          configurable_options {
            attribute_code
            attribute_uid
            label
            position
            uid
            use_default
            attribute_code
            values {
              value_index
              label
            }
            product_id
          }
          variants {
            product {
              ...OptimizedVariantFields

              price_range {
                ...PriceRangeFields
              }
            }
          }
        }
      }
      page_info {
        current_page
        page_size
        total_pages
      }
      total_count
    }
  }
  ${fragmentPriceRangeFields}
  ${fragmentOptimizedProductFields}
  ${fragmentOptimizedVariantFields}
`;
