/**
 * GraphQL Query that fetches products using received search term and the params
 * for filter, sort and pagination.
 */
export default `
  query getFacetData($filter: ProductAttributeFilterInput, $pageSize: Int = 10, $sort: ProductAttributeSortInput) {
    products(filter: $filter, pageSize: $pageSize, sort: $sort) {
      items {
        __typename
        uid
        sku
        name
        url_key
      }
    }
  }
`;
