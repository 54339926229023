import locale77dc9845 from '../../lang/pl.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"agrosimex_pl","numberFormats":{"agrosimex_pl":{"currency":{"style":"currency","currency":"PLN","currencyDisplay":"symbol"}}}},
  vueI18nLoader: false,
  locales: [{"code":"agrosimex_pl","file":"pl.js","iso":"pl_PL","defaultCurrency":"PLN"}],
  defaultLocale: "agrosimex_pl",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "/usr/src/app/lang",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  seo: false,
  baseUrl: "https://agrosimex.pl",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  country: "PL",
  normalizedLocales: [{"code":"agrosimex_pl","file":"pl.js","iso":"pl_PL","defaultCurrency":"PLN"}],
  localeCodes: ["agrosimex_pl"],
}

export const localeMessages = {
  'pl.js': () => Promise.resolve(locale77dc9845),
}
